import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../shared/services/api.service';
import { LocalStorageService  } from '../shared/services/localstorage.service';
import { Loader } from "@googlemaps/js-api-loader"

@Component({
  selector: 'app-anuncio',
  templateUrl: './anuncio.component.html',
  styleUrls: ['./anuncio.component.css','./ficha.component.css']
})
export class AnunciosComponent implements OnInit {
  page = 0
  posts = []
  verficha = false
  idficha: any
  date = new Date();
  menuOpen = false
  ficha: any
  category = 'acompanhantes'
  queryCidade = null
  ficha_zoom = false
  constructor(private apiService:ApiService, private router: Router, private localstorage: LocalStorageService) {
  }

  errorMessage:string;

  ngOnInit(): void {

    this.apiService.listPosts(this.page, 'Aguardando Aprovacao').subscribe(
      data  => {
        console.log(data)
       this.posts = data.posts;
      },
      error => {
        console.log(error)
      }
    )
  }

  aprovarAnuncio(){
    this.apiService.updatePost(this.ficha.id, 'Aprovado').subscribe(
      data  => {
        console.log(data)
        location.reload();
      },
      error => {
        console.log(error)
      }
    )
  }

  recusarAnuncio(){
    this.apiService.updatePost(this.ficha.id, 'Reprovado').subscribe(
      data  => {
        console.log(data)
        location.reload();
      },
      error => {
        console.log(error)
      }
    )
  }

  toggleMenu(){
    if(this.menuOpen){
      this.menuOpen = true
    }else{
      this.menuOpen = false
    }
  }


  verFicha(id){

    this.verficha = true
    
    this.apiService.getPostById(id).subscribe(
      data  => {
        this.ficha = data

        const index = this.posts.findIndex(object => {
          return object.id === id;
        });
    
        if(this.posts[index+1]){
          this.ficha.hasNext = true
        }else{
          this.ficha.hasNext = false
        }

        if(this.posts[index-1]){
          this.ficha.hasLast = true
        }else{
          this.ficha.hasLast = false
        }
 
        if(this.ficha.pictures){
          this.ficha.pictures = this.ficha.pictures.split(",");
        }else{
          this.ficha.pictures = []
        }

        if(this.ficha.movies){
          this.ficha.movies = this.ficha.movies.split(",");
        }else{
          this.ficha.movies = []
        }

        if(this.ficha.about_you){
          this.ficha.about_you = this.ficha.about_you.split(",");
        }else{
          this.ficha.about_you = []
        }

        if(this.ficha.locations){
          this.ficha.locations = this.ficha.locations.split(",");
        }else{
          this.ficha.locations = []
        }

        if(this.ficha.services){
          this.ficha.services = this.ficha.services.split(",");
        }else{
          this.ficha.services = []
        }

        if(this.ficha.especial_services){
          this.ficha.especial_services = this.ficha.especial_services.split(",");
        }else{
          this.ficha.especial_services = []
        }
        
        this.ficha.caches = []

    
        if(this.ficha.prices){
          if(this.ficha.prices[0].preco){
            this.ficha.caches = this.ficha.prices
          }
        }


        this.ficha.atendimento = []

        if(this.ficha.all_days == true){
          if(this.ficha.schedule_week.full_time = 1){
            this.ficha.atendimento = [{dia: 'Segunda a Domingo', horario: '24 horas'}]
          }else{
            this.ficha.atendimento = [{dia: 'Segunda a Domingo', horario: this.ficha.schedule_week.hora_inicio+":"+this.ficha.schedule_week.minuto_inicio+" - "+this.ficha.schedule_week.hora_fim+":"+this.ficha.schedule_week.minuto_fim}]
          }
        }else{

          var diasAtende = []

          this.ficha.schedule_day.forEach(day => {
            if(day.atende == 1){
              diasAtende.push(day)
            }
          });

          var diaInicial = diasAtende[0]
          var diaFinal = null
          var outrosDias = []

          diasAtende.forEach(dia => {
            if(dia['dia'] != diaInicial['dia']){
              if(dia['hora_inicio'] == diaInicial['hora_inicio'] && dia['hora_fim'] == diaInicial['hora_fim']){
                diaFinal = dia
              }else{
                outrosDias.push(dia)
              }
            }
          })

          if(diaInicial && diaFinal){
            this.ficha.atendimento.push({dia: diaInicial['dia']+' a '+diaFinal['dia'], horario: diaInicial.hora_inicio+":"+diaInicial.minuto_inicio+" - "+diaInicial.hora_fim+":"+diaInicial.minuto_fim})
          }

          outrosDias.forEach(dia => {
            this.ficha.atendimento.push({dia: dia['dia'], horario: dia.hora_inicio+":"+dia.minuto_inicio+" - "+dia.hora_fim+":"+dia.minuto_fim})
          })



          console.log(diaInicial)
          console.log(diaFinal)
        }

        this.ficha.city_camel = this.ficha.city
        this.ficha.state_camel = this.ficha.state
        
        console.log(this.ficha)

        if(this.ficha.address_lat && this.ficha.address_lng){
          let loader = new Loader({
            apiKey: 'AIzaSyAAY4q0jadr3uv_InRn5u-QWvAfsqkwUbQ'
          })
      
          loader.load().then(() => {
            let map = new google.maps.Map(document.getElementById("map"), {
              center: {lat: parseFloat(this.ficha.address_lat), lng: parseFloat(this.ficha.address_lng)},
              zoom: 15
            })

            new google.maps.Marker({
              position: {lat: parseFloat(this.ficha.address_lat), lng: parseFloat(this.ficha.address_lng)},
              map: map
            });
          })
        }

        
       
      },
      error => {
        console.log(error)
      }
     )
  }

  fechaFicha(){
    this.verficha = false
    this.ficha = ''
  }


  
}
