import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../shared/services/api.service';

@Component({
  selector: 'app-cadastraradmin',
  templateUrl: './cadastraradmin.component.html',
  styleUrls: ['./cadastraradmin.component.css']
})
export class CadastrarAdminComponent implements OnInit {
  model = {"username": '', "password": ''}
  confirm_password: string
  constructor(private apiService:ApiService, private router: Router) {
  }

  menuOpen = false;
  errorMessage:string;


  toggleMenu() {
    if(this.menuOpen == true){
      this.menuOpen = false;
    }else{
      this.menuOpen = true;
    }
  }

  salvar(){
    this.apiService.salvarAdmin(this.model).subscribe(
      data  => {
        alert('Usuário cadastrado  com sucesso')
      },
      error => {
        alert('Erro ao cadastrar o usuário')
      }
    );
  }

  goTo(path){
    this.router.navigate([path]);
  }

  ngOnInit() {
    
  }

  
}
