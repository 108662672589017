import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ControlComponent } from './control/control.component'
import { DashboardComponent } from './dashboard/dashboard.component'
import { AnunciosComponent } from './anuncio/anuncio.component'
import { AnunciosPublicadoComponent } from './anunciopublicado/anunciopublicado.component'
import { CadastrarAdminComponent } from './cadastraradmin/cadastraradmin.component'
import { TrocarsenhaComponent } from './trocarsenha/trocarsenha.component'
import { CopiaComponent } from './copia/copia.component'


const routes: Routes = [
  { path: '', component: ControlComponent },
  { path: 'control', component: ControlComponent },
  { path: 'control/login', component: ControlComponent },
  { path: 'control/dashboard', component: DashboardComponent },
  { path: 'control/anuncio', component: AnunciosComponent },
  { path: 'control/anunciopublicado', component: AnunciosPublicadoComponent },
  { path: 'control/cadastrar-admin', component: CadastrarAdminComponent },
  { path: 'control/trocar-senha', component: TrocarsenhaComponent },
  { path: 'control/importar-usuario', component: CopiaComponent }

  //control/anuncio-publicado
  ///control/senha-salvo
  //control/email-password
  ///control/redefinicao-password/091645762f3b3f1b8402a94f5b358e264a56eb91

  //https://payment.checkoutogate.com/
  //pix-letpay -> pix

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [];
