import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';

import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ApiService } from './shared/services/api.service';
import { LocalStorageService } from './shared/services/localstorage.service';
import { ControlComponent } from './control/control.component'
import { DashboardComponent } from './dashboard/dashboard.component'
import { AnunciosComponent } from './anuncio/anuncio.component'
import { CadastrarAdminComponent } from './cadastraradmin/cadastraradmin.component'
import { AnunciosPublicadoComponent } from './anunciopublicado/anunciopublicado.component'
import { TrocarsenhaComponent } from './trocarsenha/trocarsenha.component'
import { CopiaComponent } from './copia/copia.component'
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
  declarations: [
    AppComponent,
    ControlComponent,
    DashboardComponent,
    AnunciosComponent,
    CadastrarAdminComponent,
    AnunciosPublicadoComponent,
    TrocarsenhaComponent,
    CopiaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    HttpClientModule,
    AngularEditorModule 
  ],
  providers: [ApiService,LocalStorageService],
  bootstrap: [AppComponent]
})
export class AppModule { }

