import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private localstorage: LocalStorageService) {
  }

  //private USER_URL = 'http://192.168.0.232:5014/';
  private USER_URL = 'https://api.acompanhantes.love/';

  login(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      })
     };
    return this.http.post<any>(this.USER_URL+'admin/user',data, httpOptions);
  }

  listPosts(page, status) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization': 'Bearer '+this.localstorage.get('token'),
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      })
     };
    return this.http.get<any>(this.USER_URL+'admin/posts/'+status+'/'+page, httpOptions);
  }

  copia(model) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization': 'Bearer '+this.localstorage.get('token'),
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      })
     };
    return this.http.post<any>(this.USER_URL+'/admin/copia',model, httpOptions);
  }

  updatePost(id, status) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization': 'Bearer '+this.localstorage.get('token'),
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      })
     };
    return this.http.post<any>(this.USER_URL+'admin/posts/'+id+"/"+status, httpOptions);
  }

  salvarAdmin(model) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization': 'Bearer '+this.localstorage.get('token'),
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      })
     };
    return this.http.post<any>(this.USER_URL+'admin/user/register', model, httpOptions);
  }

  setSetting(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization': 'Bearer '+this.localstorage.get('token'),
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      })
     };
    return this.http.post<any>(this.USER_URL+'user/setting',data, httpOptions);
  }

  changePassword(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization': 'Bearer '+this.localstorage.get('token'),
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      })
     };
    return this.http.post<any>(this.USER_URL+'admin/user/password',data, httpOptions);
  }

  listaEstados() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      })
     };
    return this.http.get<any>('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome', httpOptions);
  }

  listaMunicipios(uf) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      })
     };
    return this.http.get<any>('https://servicodados.ibge.gov.br/api/v1/localidades/estados/'+uf+'/municipios?orderBy=nome', httpOptions);
  }

  listaMunicipiosMicro(micro) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      })
     };
    return this.http.get<any>('https://servicodados.ibge.gov.br/api/v1/localidades/microrregioes/'+micro+'/municipios?orderBy=nome', httpOptions);
  }

  sendFoto(file: File) {

    let formData = new FormData();
    formData.append('file', file, file.name);

    console.log(formData.get('file'))

    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin':'*',
        'Authorization': 'Bearer '+this.localstorage.get('token'),
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      })
     };
    return this.http.post(this.USER_URL+'photo',formData, httpOptions);
  }

  sendPost(model: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization': 'Bearer '+this.localstorage.get('token'),
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      })
     };
    return this.http.post(this.USER_URL+'post',model, httpOptions);
  }

  getPostResume(id: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization': 'Bearer '+this.localstorage.get('token'),
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      })
     };
    return this.http.get(this.USER_URL+'post/resume/'+id, httpOptions);
  }

  getPostHomeByCategory(page: any, category: string) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization': 'Bearer '+this.localstorage.get('token'),
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      })
     };
    return this.http.get(this.USER_URL+'post/home/'+category+'/'+page, httpOptions);
  }

  getPostHomeByCategoryAndCity(page: any, category: string, city: string) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization': 'Bearer '+this.localstorage.get('token'),
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      })
     };
    return this.http.get(this.USER_URL+'post/home/'+category+'/'+city+'/'+page, httpOptions);
  }

  getPostById(id: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization': 'Bearer '+this.localstorage.get('token'),
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      })
     };
    return this.http.get(this.USER_URL+'post/home/detail/'+id, httpOptions);
  }

  getCityByName(name: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      })
     };
    return this.http.get(this.USER_URL+'city/'+name, httpOptions);
  }

  getPostBearbyByCategory(category: string, lat: any, lng: any, km: any, page: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      })
     };
    return this.http.get(this.USER_URL+'post/nearby/'+category+'/'+lat+"/"+lng+"/"+km+"/"+page, httpOptions);
  }

  getPostByFilterByCategory(category: string, page: any, busca: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      })
     };
    return this.http.post(this.USER_URL+'post/filter/'+category+'/'+page,busca, httpOptions);
  }

}
