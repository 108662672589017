import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../shared/services/api.service';
import { LocalStorageService  } from '../shared/services/localstorage.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  token: any
  username: string
  constructor(private apiService:ApiService, private router: Router, private localstorage: LocalStorageService) {
  }

  menuOpen = false;

  errorMessage:string;


  toggleMenu() {
    if(this.menuOpen == true){
      this.menuOpen = false;
    }else{
      this.menuOpen = true;
    }
  }

  goTo(path){
    this.router.navigate([path]);
  }

  ngOnInit() {
    this.token = this.localstorage.get('token')
    if(!this.token){
      this.router.navigate(['control']);
    }
  }

}
