import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../shared/services/api.service';
import { LocalStorageService  } from '../shared/services/localstorage.service';

@Component({
  selector: 'app-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.css']
})
export class ControlComponent implements OnInit {
  username: string
  password: string
  alert = ''
  constructor(private apiService:ApiService, private router: Router,private localstorage: LocalStorageService) {
  }

  menuOpen = false;
  errorMessage:string;

  toggleMenu() {
    if(this.menuOpen == true){
      this.menuOpen = false;
    }else{
      this.menuOpen = true;
    }
  }

  goTo(path){
    this.router.navigate([path]);
  }

  login(){
    console.log(this.username)
    this.apiService.login({'username': this.username, "password": this.password}).subscribe(
      data  => {
        this.localstorage.set('token', data.access_token)
        this.router.navigate(['/control/dashboard']);
      },
      error => {
        this.alert = 'Erro ao realizar o login, tente novamente mais tarde'
      }
    );
  }

  closeAlerta() {
    this.alert = '';
  }

  ngOnInit() {}

}
