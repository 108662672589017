import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../shared/services/api.service';

@Component({
  selector: 'app-trocarsenha',
  templateUrl: './trocarsenha.component.html',
  styleUrls: ['./trocarsenha.component.css']
})
export class TrocarsenhaComponent implements OnInit {
  model = {"old_password": '', "new_password": ''}
  confirm_password: string
  constructor(private apiService:ApiService, private router: Router) {
  }

  menuOpen = false;
  errorMessage:string;


  toggleMenu() {
    if(this.menuOpen == true){
      this.menuOpen = false;
    }else{
      this.menuOpen = true;
    }
  }

  salvar(){
    if (this.confirm_password == this.model.new_password){
      this.apiService.changePassword(this.model).subscribe(
        data  => {
          alert('Senha salva com sucesso')
        },
        error => {
          alert('Erro ao salvar a senha')
        }
      );
    }else{
      alert('As senhas não conferem')
    }

  }

  goTo(path){
    this.router.navigate([path]);
  }

  ngOnInit() {
    
  }

  
}
